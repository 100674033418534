/**
 * A single tab page. It renders the passed template
 * via the @Input properties by using the ngTemplateOutlet
 * and ngTemplateOutletContext directives.
 */

import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-my-tab',
    styles: [
        `
            .pane {
                padding: 1em;
            }
        `
    ],
    template: `
        <div [hidden]="!active" class="pane">
            <ng-content></ng-content>
        </div>
    `
})
export class TabComponent {
    @Input('tabTitle') title: string;
    @Input('tabClass') class: string;
    @Input() active = false;
}
